<template>
  <div>
    <div style="padding:0;" class="first-footer">
      <div class="top-footer bg-white">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="netabout">
                <div>
                <router-link to="/" class="logo">
                  <img
                    style="width: 90%"
                    src="../assets/images/newlogo.webp"
                    alt="netcom"
                  />
                </router-link>
                </div>
              </div>
              <div class="contactus">
                <ul>
                  <li>
                    <div class="info">
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                      <p class="in-p font-custom">
                        Vencasa Ltd, Fulford House, Newbold Terrace,<br> Leamington
                        Spa, Warwickshire, CV32 4EA
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="info">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                      <p class="font-custom"><a style="color: #666;" :href="`mailto:contact@vencasa.co.uk`">contact@vencasa.co.uk</a></p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class=" col-md-5 offset-md-1">
              <div class="navigation">
                <h3>Navigation</h3>
                <div class="nav-footer">
                  <ul>
                    <li class="font-custom"><router-link  to="/">Home</router-link></li>
                    <li class="font-custom"><router-link to="/listing">Listings</router-link></li>
                    <li class="font-custom"><router-link  to="/favourites">Favorites</router-link></li>
                    <li class="font-custom"><router-link  to="/terms-of-use">Terms and Conditions</router-link></li>
                    <li class="font-custom"><router-link  to="/data-deletion-policy">Data Deletion Policy</router-link></li>
                  </ul>
                  <ul class="nav-right">
                    <li class="font-custom"><router-link style=" white-space: nowrap;" to="/my-buying">My Buying</router-link></li>
                     <li class="font-custom"><router-link style=" white-space: nowrap;" to="/my-listing">My Selling</router-link></li>
                    <li class="font-custom">
                      <router-link style=" white-space: nowrap;" to="/profile">Change Details</router-link>
                    </li>
                    <li class="font-custom"><router-link style=" white-space: nowrap;" to="/privacy-policy">Privacy Policy</router-link></li>
                  </ul>
                  <ul class="nav-right">
                    <li class="font-custom"><a  href="/blog" >Blog</a></li>
                      <li class="font-custom"><a href="/become-an-affiliate" >Become Ambassador</a></li>
                    <li class="font-custom">
                      <router-link  to="/contact-us">Contact Us</router-link>
                    </li>
                    <li class="font-custom">
                      <router-link  to="/voucher-and-offers-terms">Voucher And Offer Terms</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="widget">
                <h3>Recent Blogs</h3>
                <div class="twitter-widget contuct">
                  <div class="twitter-area">
                    <div v-for="blog in (blogs ? blogs.slice(0,2) : [])" :key="blog.id" class="single-item">
                      <div @click="$router.push(`/blog/${blog.slug}`)" class="text hover">
                        <h5 style="font-weight: bold" class="font-custom">{{ blog.title }}</h5>
                        <h5 class="font-custom">
                          {{ blog.createdAtDate }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  computed:{
    ...mapGetters({
      blogs: 'getBlogs'
    })
  },
  methods:{
    navigate(path){
        this.$router.push(path)
    },
  },
  mounted(){
    if (!this.blogs) {
      this.$store.dispatch("blogs", { page: 1, perPage: 10 });
    }
  }
};
</script>

<style scoped>

@import url("../assets/css/fontawesome-all.css");
@import url("../assets/css/fontawesome-5-all.min.css");
@import url("../assets/css/font-awesome.min.css");
@import url("../assets/css/styles.css");
@import url("../assets/css/colors/black.css");

.font-custom{
  font-family: 'Lato' !important;
 
 
}

</style>
